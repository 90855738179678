import * as React from "react";
import {
    Grid,
    Alert,
    Box,
    Typography,
    Button,
    Stack,
    AlertTitle,
    TextField,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

// component
import { useFirebase } from "../utils/useFirebase";
import theme from "../utils/theme";
import Link from "../utils/link";
import Section from "../utils/section";

const validationSchema = yup.object({
    name: yup.string().required(),
    piva: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required(),
    privacy: yup.number().oneOf([1]),
});

const ContactForm = () => {
    const [isSent, setIsSent] = React.useState(false);
    const { firestore } = useFirebase();

    const formik = useFormik({
        initialValues: {
            name: "",
            piva: "",
            phone: "",
            email: "",
            message: "",
            privacy: 0,
            marketing: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (firestore) {
                const db = firestore.getFirestore();
                firestore
                    .addDoc(firestore.collection(db, "contacts"), {
                        to: [values.email],
                        bcc: ["info@mettifogo.eu"],
                        message: {
                            subject: `Richiesta informazioni da ${values.name} - Mettifogo S.r.l`,
                            text: `Gentile ${values.name}, grazie per averci contattati. Saremo felici di prendere in carico la Sua richiesta e risponderLe nel più breve tempo possibile. Per eventuali comunicazioni potrà essere contattato via telefono al numero ${values.phone} o al suo indirizzo email ${values.email}. Il suo messaggio: ${values.message}. Partita iva: ${values.piva}. Team Mettifogo S.r.l`,
                            html: `
                            <p>Gentile ${values.name},<br/>grazie per averci contattati.</p>
                            <p>Saremo felici di prendere in carico la Sua richiesta e risponderLe nel più breve tempo possibile.</p>
                            <p>Per eventuali comunicazioni potrà essere contattato via telefono al numero <strong>${values.phone}</strong> o al suo indirizzo email <strong>${values.email}</strong>.</p>
                            <p>Il suo messaggio: <br/>${values.message}</p>
                            <p>Partita iva: <br/>${values.piva}</p>
                            <p><i>Team Mettifogo S.r.l</i></p>
                        `,
                        },
                    })
                    .then(() => {
                        setIsSent(true);
                        resetForm();
                    });
            }
        },
    });

    return (
        <Box id="modulo-contatto" pt={3}>
            <Section>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                        <Stack spacing={1} textAlign="center">
                            <Typography variant="h3" component="h2">
                                Di che cosa hai bisogno?
                            </Typography>
                            <Typography>
                                Compila il form senza alcun impegno e verrai contattato al più presto.
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>
                                <Stack spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        variant="outlined"
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Telefono"
                                        variant="outlined"
                                        id="phone"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        variant="outlined"
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Partita iva"
                                        variant="outlined"
                                        id="piva"
                                        name="piva"
                                        value={formik.values.piva}
                                        onChange={formik.handleChange}
                                        error={formik.touched.piva && Boolean(formik.errors.piva)}
                                    />
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Messaggio"
                                        variant="outlined"
                                        id="message"
                                        name="message"
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        error={formik.touched.message && Boolean(formik.errors.message)}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="privacy"
                                                name="privacy"
                                                value={1}
                                                onChange={formik.handleChange}
                                            />
                                        }
                                        label={
                                            <>
                                                Ho letto e accetto la{" "}
                                                <Link
                                                    title="Privacy policy"
                                                    type="external"
                                                    href="https://www.iubenda.com/privacy-policy/97449482"
                                                    underline="always"
                                                >
                                                    privacy policy
                                                </Link>
                                            </>
                                        }
                                        style={{
                                            color:
                                                formik.touched.privacy && Boolean(formik.errors.privacy)
                                                    ? theme.palette.error.main
                                                    : theme.palette.text.primary,
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="marketing"
                                                name="marketing"
                                                value={1}
                                                onChange={formik.handleChange}
                                            />
                                        }
                                        label=" Do il consenso al trattamento dei miei dati per fini commerciali"
                                        style={{
                                            color:
                                                formik.touched.marketing && Boolean(formik.errors.marketing)
                                                    ? theme.palette.error.main
                                                    : theme.palette.text.primary,
                                        }}
                                    />
                                    <Box justifyContent="flex-end" display="flex">
                                        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                                            contattaci
                                        </Button>
                                    </Box>
                                </Stack>
                            </form>

                            {isSent && (
                                <Box mt={4}>
                                    <Alert severity="success">
                                        <AlertTitle>Grazie per averci scritto.</AlertTitle>
                                        Sarà contattato a breve da un nostro operatore.
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Section>
        </Box>
    );
};

export default ContactForm;
