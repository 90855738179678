import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Button, Box, Grid, Typography, Stack } from "@mui/material";

// components
import Link from "../utils/link";
import Page from "../utils/page";
import Hero from "../utils/hero";
import theme from "../utils/theme";
import Section from "../utils/section";
import Address from "../components/address";
import ContactForm from "../components/contact-form";

// markup
const ContattiPage = () => {
    return (
        <Page
            title="Contatti"
            description="Mettifogo Srl ditta specializzata nella produzione e commercializzazione articoli personalizzati per la ristorazione e il mondo Horeca."
            type="ContactPage"
            image="/contatti.jpg"
        >
            <Hero
                title="Contatti"
                description="Hai qualche dubbio? Siamo a tua completa disposizione per rispondere a qualsiasi domanda."
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/backgrounds/fragranza-menta.jpg"
                        alt="Fragranza menta"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} md={5}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11203.426658234346!2d11.1258547!3d45.4122299!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf315f7349d4d0314!2sMettifogo!5e0!3m2!1sit!2sit!4v1641317272091!5m2!1sit!2sit"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            loading="lazy"
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Address />
                    </Grid>
                </Grid>
            </Section>

            <ContactForm />
        </Page>
    );
};

export default ContattiPage;
